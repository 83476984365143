<template>
  <div>
    <div v-if="step == 1">
      <div>
        <div class="form-check">
          <input
            id="flexRadioDefault1"
            v-model="value"
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            :value="1"
          >
          <label
            class="form-check-label"
            for="flexRadioDefault1"
          >
            I already have my SPC Token
          </label>
        </div>
        <div class="form-check">
          <input
            id="flexRadioDefault2"
            v-model="value"
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            :value="2"
          >
          <label
            class="form-check-label"
            for="flexRadioDefault2"
          >
            I don’t have my SPC Token
          </label>
        </div>
      </div>
      <div
        v-show="value == 1"
        class="mt-3"
      >
        <div class="form-floating">
          <label for="floatingTextarea2"> Please enter your SPC Token </label>
          <textarea
            id="floatingTextarea2"
            v-model="text_area"
            class="form-control"
            placeholder="Your SPC Token"
            style="height: 100px"
          />
        </div>
        <button class="mt-2 btn btn-primary validate">
          Validate SPC Token
        </button>
      </div>
      <div
        v-show="value == 2"
        class="mt-3"
      >
        <div class="d-flex flex-column flex-wrap">
          <div class="form-check">
            <input
              id="spc"
              v-model="token_now"
              class="form-check-input"
              type="checkbox"
              value=""
            >
            <label
              class="form-check-label"
              for="spc"
            > I want to create my SPC Token Now </label>
          </div>
          <div
            v-if="token_now"
            class="form-check mt-1"
          >
            <input
              id="ip"
              v-model="ip_address"
              class="form-check-input"
              type="checkbox"
              value=""
            >
            <label
              class="form-check-label"
              for="ip"
            >
              My exiting IP Address x.x.x.x is already whitelisted by iConnectiv
            </label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="mb-3 col">
            <label
              for="exampleFormControlInput1"
              class="form-label"
            >Name</label>
            <input
              v-model="item.name"
              type="text"
              class="form-control"
            >
            <span class="d-block mt-1">The human readable display name</span>
          </div>
          <div class="mb-3 col">
            <label
              for="exampleFormControlInput1"
              class="form-label"
            >SPID</label>
            <input
              v-model="item.spid"
              type="text"
              class="form-control"
            >
            <span class="d-block mt-1">The SPID</span>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col">
            <label
              for="exampleFormControlInput1"
              class="form-label"
            >STI-PA Account ID</label>
            <input
              v-model="item.account_id"
              type="text"
              class="form-control"
            >
            <span class="d-block mt-1">The account ID used for accessing the STI-PA API</span>
          </div>
          <div class="mb-3 col">
            <label
              for="exampleFormControlInput1"
              class="form-label"
            >STI-PA User ID</label>
            <input
              v-model="item.user_id"
              type="text"
              class="form-control"
            >
            <span class="d-block mt-1">The user ID used for accessing the STI-PA API</span>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col">
            <label
              for="exampleFormControlInput1"
              class="form-label"
            >STI-PA Password</label>
            <input
              v-model="item.pass"
              type="text"
              class="form-control"
            >
            <span class="d-block mt-1">The password used for accessing the STI-PA API</span>
          </div>
          <div class="mb-3 col">
            <label
              for="exampleFormControlInput1"
              class="form-label"
            >Comment</label>
            <input
              v-model="item.comment"
              type="text"
              class="form-control"
            >
            <span class="d-block mt-1">A short comment for additional information</span>
          </div>
        </div>
        <button
          class="mt-2 btn btn-primary validate"
          :disabled="!user_id || !pass || !account_id"
          @click="login"
        >
          Create SPC Token Now
        </button>
      </div>
    </div>
    <div v-if="step == 2">
      <div class="form-floating">
        <label for="floatingTextarea2"> Your SPC Token is as follows: </label>
        <textarea
          id="floatingTextarea2"
          v-model="token"
          class="form-control"
          placeholder="Your SPC Token"
          style="height: 100px"
        />
        <div class="d-flex mt-2">
          <button
            type="button"
            class="btn btn-primary mr-2"
          >
            Save As
          </button>
          <button
            type="button"
            class="btn btn-primary mr-2"
          >
            Copy
          </button>
          <button
            type="button"
            class="btn btn-primary mr-2"
          >
            Email me
          </button>
        </div>
        <div class="mt-3">
          <button
            type="button"
            class="btn btn-success"
          >
            Generate Certificate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  data() {
    return {
      step: 1,
      value: 1,
      token_now: false,
      ip_address: false,
      account_id: null,
      text_area: null,
      user_id: 'peeringhub',
      pass: 'Dnl.12345678',
      token: null,
      item: {
        name: null,
        spid: null,
        account_id: null,
        user_id: null,
        pass: null,
        comment: null,
      },
    }
  },
  methods: {
    login() {
      var headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      let url = 'https://authenticate-api-stg.iconectiv.com/api/v1/auth/login'
      const data = {
        userId: this.user_id,
        password: this.pass,
      }
      axios
        .post(url, data, headers)
        .then((res) => {
          this.token = res.data.accessToken;
        })
        .catch((err) => {
          console.log(err.response, err.request, err.message)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 0 !important;
}
/deep/ .ant-form-item-label,
label {
  min-width: 210px !important;
  text-align: left;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
</style>

